import React from 'react';
import { graphql } from 'gatsby';
import SEO from '../../components/SEO';
import Layout from '../../layouts/index';
import ContactImage from '../../images/ceo.jpg'

const Contact = (props) => {
  const site = props.data.site;
  return (
    <Layout bodyClass="page-contact">
      <SEO title="Kontakt" />
      <div className="intro intro-small">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <h1>Kontakt</h1>
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="row">
          <div className="col-md-4 mb-2">
            <address>
              <span className="font-weight-bold">Ahne Schreibwaren</span>
              <br />
              Victor-Kretz-Straße 18
              <br />
              77723 Gengenbach
            </address>
            <div>
                <strong>Telefon: </strong>
                <a href={`tel:${site.siteMetadata.contact.phone}`}>
                {props.data.site.siteMetadata.contact.phone}
                </a>
                <br />
                <strong>E-Mail: </strong>
                <a href={`mailto:${site.siteMetadata.contact.email}`}>
                  {props.data.site.siteMetadata.contact.email}
                </a>
            </div>
          </div>
          <div className="col-8">
          <figure>
            <img src={ContactImage} title="Geschäftsführer Thomas Ahne" alt="Geschäftsführer Thomas Ahne" />
            <figcaption className="img-caption">
              Thomas Ahne
              <span className="profession">Geschäftsführer</span>
            </figcaption>
          </figure> 
          </div>
          <div className="col-12 mt-6">
            <h4>Öffnungszeiten</h4>
            <table className="table table-borderless opening-hours-table">
              <tbody>
                <tr>
                  <td className="day font-weight-bold">Montag bis Freitag</td>
                  <td className="opens-closes">08:00 - 18:00 Uhr</td>
                </tr>
                <tr>
                  <td className="day font-weight-bold">Samstag</td>
                  <td className="opens-closes">08:00 - 13:00 Uhr</td>
                </tr>
                <tr>
                  <td className="day font-weight-bold">Sonn- und Feiertage</td>
                  <td className="opens-closes">Geschlossen</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export const query = graphql`
  query {
    site {
      siteMetadata {
        contact {
          email
          phone
        }
      }
    }
  }
`;

export default Contact;
